<template>
  <div class="g_02-index">
    <my-search-bar>
      <el-form :inline="true">
        <!-- <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        -->
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">刷新</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="g_02List"
      border
      header-cell-class-name="bg-info text-light"
      :height="height"
      >
      <el-table-column fixed="left" type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="machineName" header-align="left" align="left" label="机械设备名称"></el-table-column>
      <el-table-column fixed="left" prop="num" :formatter="numberFormatter" width="130" header-align="left" align="left" label="数量(台)"></el-table-column>
      <el-table-column fixed="left" prop="perfectPercent" :formatter="numberFormatter" width="160" header-align="center" align="center" label="实际完好度%"></el-table-column>
      <el-table-column label="机械设备的规格及数量" header-align="center">
        <el-table-column fixed="left" prop="machineSpec1" width="120" header-align="center" align="center" label="规格"></el-table-column>
        <el-table-column fixed="left" prop="num1" :formatter="numberFormatter" width="120" header-align="center" align="center" label="数量(台)"></el-table-column>
        <el-table-column fixed="left" prop="machineSpec2" width="120" header-align="center" align="center" label="规格"></el-table-column>
        <el-table-column fixed="left" prop="num2" :formatter="numberFormatter" width="120" header-align="center" align="center" label="数量(台)"></el-table-column>
        <el-table-column fixed="left" prop="machineSpec3" width="120" header-align="center" align="center" label="规格"></el-table-column>
        <el-table-column fixed="left" prop="num3" :formatter="numberFormatter" width="120" header-align="center" align="center" label="数量(台)"></el-table-column>
        <el-table-column fixed="left" prop="machineSpec4" width="120" header-align="center" align="center" label="规格"></el-table-column>
        <el-table-column fixed="left" prop="num4" :formatter="numberFormatter" width="120" header-align="center" align="center" label="数量(台)"></el-table-column>
      </el-table-column>
      <el-table-column v-if="showEditBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
        </template>
      </el-table-column>
    </el-table>

    <div class="worker">
      <el-form label-width="120px" :inline="true">
        <el-form-item label="管理人员数量">
          <el-input v-model.number="managerNum"></el-input>
        </el-form-item>
        <el-form-item label="技术人员数量">
          <el-input v-model.number="tecNum"></el-input>
        </el-form-item>
        <el-form-item label="本单位职工数量">
          <el-input v-model.number="normalNum"></el-input>
        </el-form-item>
        <el-form-item label="临时工数量">
          <el-input v-model.number="tempNum"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveWorker">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <my-dialog
      ref="myDialog"
      title="添加记录"
      size="md"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <el-form :model="g_02" label-width="120px">
        <el-form-item label="机械设备名称">
          <el-input v-model="g_02.machineName"></el-input>
        </el-form-item>
        <el-form-item label="数量(台)">
          <el-input v-model.number="g_02.num"></el-input>
        </el-form-item>
        <el-form-item label="实际完好度%">
          <el-input v-model="g_02.perfectPercent"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="g_02.machineSpec1"></el-input>
        </el-form-item>
        <el-form-item label="数量(台)">
          <el-input v-model.number="g_02.num1"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="g_02.machineSpec2"></el-input>
        </el-form-item>
        <el-form-item label="数量(台)">
          <el-input v-model.number="g_02.num2"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="g_02.machineSpec3"></el-input>
        </el-form-item>
        <el-form-item label="数量(台)">
          <el-input v-model.number="g_02.num3"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="g_02.machineSpec4"></el-input>
        </el-form-item>
        <el-form-item label="数量(台)">
          <el-input v-model.number="g_02.num4"></el-input>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import G11Model from '@/model/G11Model'
import PageOperation from '@/mixins/PageOperation'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'

export default {
  name: 'G11Index',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar
  },
  filters: {
  },
  computed: {
    height () {
      return document.body.clientHeight - 250
    },
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && config.applyIdentities.indexOf(this.userIdentityGuid) > -1
    },
    showEditBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.g_02List.length) {
        return this.g_02List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g_02List: [],
      g_02Model: new G11Model(),
      g_02: {
        machineName: '',
        num: 0,
        perfectPercent: 0,
        machineSpec1: '',
        num1: 0,
        machineSpec2: '',
        num2: 0,
        machineSpec3: '',
        num3: 0,
        machineSpec4: '',
        num4: 0
      },
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: '',
      managerNum: 0,
      tecNum: 0,
      normalNum: 0,
      tempNum: 0
    }
  },
  methods: {
    numberFormatter (row, column) {
      return row[column.property] === 0 ? '' : row[column.property]
    },
    saveWorker () {
      axios.post(`${config.restHost}/g_02/saveWorker`, {
        guid: utility.getUuid(),
        managerNum: this.managerNum,
        tecNum: this.tecNum,
        normalNum: this.normalNum,
        tempNum: this.tempNum
      }, {
        params: {
          planStatExaminationGuid: this.planStatExaminationGuid
        }
      })
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    cellClassName ({ row, column }) {
      if (!row.empty && column.property === 'totalMonthPercent' && parseFloat(row[column.property]) > 100) {
        return 'warning'
      }
      return ''
    },
    handleBatchDeleteClick () {
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      this.g_02.perfectPercent = parseFloat(this.g_02.perfectPercent)
      axios.post(`${config.restHost}/g_02`, this.g_02, {
        params: {
          planStatExaminationGuid: this.planStatExaminationGuid
        }
      })
        .then(res => {
          this.$refs.myDialog.close()
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
            this._getG_02List()
          } else {
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g_02Identity', row)
        this._approval(row)
      } else {
        this.g_02Model = new G11Model(row)
        this.g_02Model.currentMonthNum = parseFloat(row.currentMonthNum)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG_02List()
      this._getG_02Worker()
    },
    handleAddClick () {
      this.g_02 = {
        guid: utility.getUuid(),
        machineName: '',
        num: 0,
        perfectPercent: 0,
        machineSpec1: '',
        num1: 0,
        machineSpec2: '',
        num2: 0,
        machineSpec3: '',
        num3: 0,
        machineSpec4: '',
        num4: 0
      }
      this.$refs.myDialog.open()
    },
    handleDeleteClick (row) {
      axios.delete(`${config.restHost}/g_02`, {
        params: {
          guid: row.guid
        }
      })
        .then(res => {
          if (res.data.code === 1) {
            this._getG_02List()
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleEditClick (row) {
      this.g_02 = {
        guid: utility.getUuid(),
        meterageCode: row.meterageCode,
        meterageName: row.meterageName,
        meterageListGuid: row.meterageListGuid,
        currentYearPlanNum: 0,
        currentMonthPlanNum: 0
      }
      this.$refs.myDialog.open()
    },
    _getG_02Worker () {
      axios.get(`${config.restHost}/g_02/g_02Worker`, {
        params: {
          planStatExaminationGuid: this.planStatExaminationGuid
        }
      })
        .then(res => {
          if (res.data.code === 1) {
            this.managerNum = res.data.data.managerNum
            this.tecNum = res.data.data.tecNum
            this.normalNum = res.data.data.normalNum
            this.tempNum = res.data.data.tempNum
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    _getG_02List () {
      this.$myLoading()
      axios.get(`${config.restHost}/g_02`, {
        params: {
          planStatExaminationGuid: this.planStatExaminationGuid
        }
      })
        .then(res => {
          this.$loading().close()
          if (res.data.code === 1) {
            this.g_02List = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '列表加载失败！'
            })
          }
        })
        .catch(err => {
          this.$loading().close()
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    }
  },
  created () {
    this._getG_02List()
    this._getG_02Worker()
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  background-color: red;
}
.worker {
  text-align: right;
  margin-top: 20px;
}
</style>
